<template>
  <div class="btn-group dropdown new" ref="dropdown">
    <span class="material-symbols-outlined title c-pointer" :class="classObject" @click="toggle" v-if="simple">
      more_vert
    </span>
    <button type="button" class="btn" :class="classObject" @click="toggle" v-else>
      <slot name="button"/>
    </button>
    <div class="dropdown-menu" :class="menuClass">
      <header v-if="header" class="dropdown-header">
        {{ header }}
      </header>
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Dropdown',
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    header: {
      type: String,
      default: '',
    },
    right: {
      type: Boolean,
      default: false,
    },
    menuSize: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'button',
    },
  },
  // props: {
  //   variant: {
  //     type: String,
  //     default: 'link',
  //   },
  // },
  data() {
    return {
      isShowing: false,
    };
  },
  computed: {
    classObject() {
      return [
        this.variant,
      ];
    },
    menuClass() {
      return [
        { show: this.isShowing },
        { 'dropdown-menu-right': this.right },
        this.menuSize,
      ];
    },
    visible() {
      return this.$refs.current?.visible ?? false;
    },
  },
  created() {
  },
  methods: {
    toggle() {
      if (this.isShowing) {
        this.hide();
      } else {
        this.show();
      }
    },
    show() {
      this.isShowing = true;
      window.addEventListener('click', this.clickOutside);
    },
    hide() {
      this.isShowing = false;
      window.removeEventListener('click', this.clickOutside);
    },
    clickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.hide();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown.new .dropdown-menu {
  inset: 0px 0px auto auto;
}
.btn {
  &.light {
    background-color: #fafafa;
    border-color: #fafafa;
    color: #212529;

    &:hover {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
      box-shadow: 0 5px 10px rgba(250, 250, 250, 0.4);
    }
  }
}

.dropdown {
  .dropdown-menu {
    &.dropdown-menu-right {
      right: 0 !important;
      left: auto !important;
    }
    &.dropdown-menu-left {
      right: auto !important;
      left: 0 !important;
    }
    &.lg {
      min-width: 500px;
    }
    .form-group {
      padding: 0 15px;
    }
    .btn {
      margin: 0 15px;
    }
  }
}
buttoncomponent {
  padding: 20px;
  cursor: pointer;
}
</style>
